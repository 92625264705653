.logo-vutp {
  width: 100% !important;
  height: 57px;
  background-color: #17a2b8;
  text-align: center;
}

#vutp-logo-link {
  position: relative;
  top: 12px;
  text-align: center;
  padding: 0px;
}
