.clickable {
  cursor: pointer;
}

.icon-node {
  color: var(--node-color);
}

.icon-services {
  color: var(--services-color);
}

.icon-network {
  color: var(--network-color);
}

.icon-node.active,
.icon-services.active,
.icon-network.active {
  color: white;
}
