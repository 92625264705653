/*
 * Links
 */

a:not(.nav-link):not(.login-logo a) {
  color: #17a2b8;
}
a:hover:not(.nav-link):not(.login-logo a) {
  color: #138496;
}

/*.nav-pills .nav-link.active {
  background-color: #17a2b8;
}
.nav-pills .nav-link:not(.active):hover {
  color: #17a2b8;
}*/
