.page-item.active.disabled .page-link {
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: #17a2b8 !important;
  border-color: #138496 !important;
}
.page-item:not(.active) .page-link {
  color: #17a2b8 !important;
}
.page-item:not(.active) .page-link:hover {
  color: #138496;
}