.full-size-card {
  width: 100%;
}

/*** LINE ON TOP CARD ***/
.card.top-line-info {
  border-top: 3px solid #17a2b8;
}

.card.top-line-success {
  border-top: 3px solid #28a745;
}

.card.top-line-node {
  border-top: 3px solid var(--node-color);
}

.card.top-line-services {
  border-top: 3px solid var(--services-color);
}

.card.top-line-network {
  border-top: 3px solid var(--network-color);
}

/*** TEST BLOCK STYLE ***/
.card.tile-node {
  border: 1px solid var(--node-color);
}

.card-body.tile-node {
  color: var(--node-color);
}

.card.tile-services {
  border: 1px solid var(--services-color);
}

.card-body.tile-services {
  color: var(--services-color);
}

.card.tile-network {
  border: 1px solid var(--network-color);
}

.card-body.tile-network {
  color: var(--network-color);
}

.card.tile-env {
  border: 1px solid var(--env-color);
}

.card-body.tile-env {
  color: var(--env-color);
}

/*** TEST BLOCK SELECTED STYLE ***/

.card.tile-node.active {
  background-color: var(--node-color);
}

.card.tile-services.active {
  background-color: var(--services-color);
}

.card.tile-network.active {
  background-color: var(--network-color);
}

.card.tile-env.active {
  background-color: var(--env-color);
}

.card-body.tile-node.active,
.card-body.tile-services.active,
.card-body.tile-network.active,
.card-body.tile-env.active {
  color: white;
}
