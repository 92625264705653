:root {
  --node-color: #dc3545;      /* bootstrap color danger       alternative colors - #F55665, #EB776C */
  --services-color: #007bff;  /* bootstrap color primary      alternative colors - #2F91C2, #376399 */
  --network-color: #28a745;   /* bootstrap color success      alternative colors - #A19C1A, #8A993F */
  --env-color: #6c757d;
}

@import url('../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('../node_modules/admin-lte/dist/css/adminlte.min.css');
@import url('../node_modules/leaflet/dist/leaflet.css');
@import url('../node_modules/react-toastify/dist/ReactToastify.css');
@import url('./styles/background.css');
@import url('./styles/body.css');
@import url('./styles/button.css');
@import url('./styles/card.css');
@import url('./styles/checkbox.css');
@import url('./styles/content.css');
@import url('./styles/dropdown-button.css');
@import url('./styles/footer.css');
@import url('./styles/form-control.css');
@import url('./styles/icon.css');
@import url('./styles/input.css');
@import url('./styles/jsoneditor.css');
@import url('./styles/label.css');
@import url('./styles/link.css');
@import url('./styles/logo.css');
@import url('./styles/nav.css');
@import url('./styles/other.css');
@import url('./styles/pagination.css');
@import url('./styles/popover.css');
@import url('./styles/react-datepicker.css');
