.dropdown-schedule-menu {
  width: 20rem;
  margin-left: -17.5rem !important;
}
.sw_label {
  color: black;
  font-size: 1rem;
  margin-bottom: 0;
}
.react-switch {
  vertical-align: middle;
  margin-left: 15px;
}
.dropdown-warning {
  font-size: 0.8rem;
}
.monitoring-warning {
  text-align: left;
  font-size: 0.8rem;
  vertical-align: top;
  /*top:-20px;*/
  position: relative;
}
.block {
  width: 100%;
}
.dropdown-schedule-toggle {
  max-width: 35px;
}
.enable-notif-checkbox {
  position: relative;
  align-self: baseline;
  margin-left: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
}