label {
  user-select: none;
}

.label-60 {
  min-width: 60px !important;
  display: inline-block !important
}

.label-coloured {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
}