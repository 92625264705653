body {
  background-color: #e9ecef;
}
body ::-moz-selection {
  background: #17a2b8;
  color: white;
}

body ::selection {
  background: #17a2b8;
  color: white;
}

.modal-open {
  padding: 0 !important;
}
.btn-link {
  color: #17a2b8 !important;
}
.btn-link:hover {
  color: #138496 !important;
}
.full-size-card {
  width: 100%;
}

/*** LINE ON TOP CARD ***/
.card.top-line-info {
  border-top: 3px solid #17a2b8;
}

.card.top-line-success {
  border-top: 3px solid #28a745;
}

.card.top-line-node {
  border-top: 3px solid var(--node-color);
}

.card.top-line-services {
  border-top: 3px solid var(--services-color);
}

.card.top-line-network {
  border-top: 3px solid var(--network-color);
}

/*** TEST BLOCK STYLE ***/
.card.tile-node {
  border: 1px solid var(--node-color);
}

.card-body.tile-node {
  color: var(--node-color);
}

.card.tile-services {
  border: 1px solid var(--services-color);
}

.card-body.tile-services {
  color: var(--services-color);
}

.card.tile-network {
  border: 1px solid var(--network-color);
}

.card-body.tile-network {
  color: var(--network-color);
}

.card.tile-env {
  border: 1px solid var(--env-color);
}

.card-body.tile-env {
  color: var(--env-color);
}

/*** TEST BLOCK SELECTED STYLE ***/

.card.tile-node.active {
  background-color: var(--node-color);
}

.card.tile-services.active {
  background-color: var(--services-color);
}

.card.tile-network.active {
  background-color: var(--network-color);
}

.card.tile-env.active {
  background-color: var(--env-color);
}

.card-body.tile-node.active,
.card-body.tile-services.active,
.card-body.tile-network.active,
.card-body.tile-env.active {
  color: white;
}

/* CHECKBOX */

/* The container */
.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.input-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.input-container input:checked ~ .checkmark {
  background-color: #17a2b8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.content-navigation {
  padding: 0 .5rem 10px .5rem;
}
.dropdown-schedule-menu {
  width: 20rem;
  margin-left: -17.5rem !important;
}
.sw_label {
  color: black;
  font-size: 1rem;
  margin-bottom: 0;
}
.react-switch {
  vertical-align: middle;
  margin-left: 15px;
}
.dropdown-warning {
  font-size: 0.8rem;
}
.monitoring-warning {
  text-align: left;
  font-size: 0.8rem;
  vertical-align: top;
  /*top:-20px;*/
  position: relative;
}
.block {
  width: 100%;
}
.dropdown-schedule-toggle {
  max-width: 35px;
}
.enable-notif-checkbox {
  position: relative;
  align-self: baseline;
  margin-left: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Footer appearing issue */
.content-wrapper {
  min-height: calc(100vh - 57px);
}
.main-footer {
  padding: 16px;
}
.form-control:focus {
  border-color: #25a2b8;
  box-shadow:inset 0 0 0 transparent,0 0 0 .2rem rgba(37,162,184,.25);
}
.clickable {
  cursor: pointer;
}

.icon-node {
  color: var(--node-color);
}

.icon-services {
  color: var(--services-color);
}

.icon-network {
  color: var(--network-color);
}

.icon-node.active,
.icon-services.active,
.icon-network.active {
  color: white;
}

#result-search-field {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.jsoneditor {
  border: 1px solid #17a2b8 !important;
}
.jsoneditor-menu {
  background-color: #17a2b8 !important;
  border-bottom: 1px solid #17a2b8 !important;
}
label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.label-60 {
  min-width: 60px !important;
  display: inline-block !important
}

.label-coloured {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
}
/*
 * Links
 */

a:not(.nav-link):not(.login-logo a) {
  color: #17a2b8;
}
a:hover:not(.nav-link):not(.login-logo a) {
  color: #138496;
}

/*.nav-pills .nav-link.active {
  background-color: #17a2b8;
}
.nav-pills .nav-link:not(.active):hover {
  color: #17a2b8;
}*/

.logo-vutp {
  width: 100% !important;
  height: 57px;
  background-color: #17a2b8;
  text-align: center;
}

#vutp-logo-link {
  position: relative;
  top: 12px;
  text-align: center;
  padding: 0px;
}

.nav-sidebar .nav-header:not(:first-of-type) {
  padding: 1.7rem 1rem 0.5rem;
}

.nav-pills .nav-item.color-node .nav-link.active {
  background-color: var(--node-color);
}
.nav-pills .nav-item.color-node .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-node .nav-link:hover {
  color: var(--node-color);
}
.nav-pills .nav-item.color-services .nav-link.active {
  background-color: var(--services-color);
}
.nav-pills .nav-item.color-services .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-services .nav-link:hover {
  color: var(--services-color);
}
.nav-pills .nav-item.color-network .nav-link.active {
  background-color: var(--network-color);
}
.nav-pills .nav-item.color-network .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-network .nav-link:hover {
  color: var(--network-color);
}
.nav-pills .nav-item.color-favorites .nav-link.active {
  background-color: #ffa500;
}
.nav-pills .nav-item.color-favorites .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-favorites .nav-link:hover {
  color: #ffa500;
}

.notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.page-item.active.disabled .page-link {
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: #17a2b8 !important;
  border-color: #138496 !important;
}
.page-item:not(.active) .page-link {
  color: #17a2b8 !important;
}
.page-item:not(.active) .page-link:hover {
  color: #138496;
}
.popover {
  max-width: 500px;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container > input {
    display: block;
    width: 100%;
}
:root {
  --node-color: #dc3545;      /* bootstrap color danger       alternative colors - #F55665, #EB776C */
  --services-color: #007bff;  /* bootstrap color primary      alternative colors - #2F91C2, #376399 */
  --network-color: #28a745;   /* bootstrap color success      alternative colors - #A19C1A, #8A993F */
  --env-color: #6c757d;
}

