.nav-sidebar .nav-header:not(:first-of-type) {
  padding: 1.7rem 1rem 0.5rem;
}

.nav-pills .nav-item.color-node .nav-link.active {
  background-color: var(--node-color);
}
.nav-pills .nav-item.color-node .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-node .nav-link:hover {
  color: var(--node-color);
}
.nav-pills .nav-item.color-services .nav-link.active {
  background-color: var(--services-color);
}
.nav-pills .nav-item.color-services .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-services .nav-link:hover {
  color: var(--services-color);
}
.nav-pills .nav-item.color-network .nav-link.active {
  background-color: var(--network-color);
}
.nav-pills .nav-item.color-network .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-network .nav-link:hover {
  color: var(--network-color);
}
.nav-pills .nav-item.color-favorites .nav-link.active {
  background-color: #ffa500;
}
.nav-pills .nav-item.color-favorites .nav-link.active:hover {
  color: white;
}
.nav-pills .nav-item.color-favorites .nav-link:hover {
  color: #ffa500;
}
